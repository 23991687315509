<template>
    <div v-show="isShow" class="quiz-timer mb-5">
        <div class="card">
            <div class="card-body text-center">
                <countdown :time="duration" @end="handleCountdownEnd" @progress="handleCountdownProgress" :transform="transform">
                    <template slot-scope="props">{{ props.hours }} : {{ props.minutes }} : {{ props.seconds }}
                    </template>
                </countdown>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "QuizTimer",
        props: ['duration', 'isShow'],
        components: {
            countdown: () => import('@chenfengyuan/vue-countdown'),
        },
        methods: {
            handleCountdownEnd() {
                this.$emit('endTime', true);
            },
            handleCountdownProgress(time){
                this.$emit('countdownProgress', time);
            },
            transform(props) {
                Object.entries(props).forEach(([key, value]) => {
                    // Adds leading zero
                    const digits = value < 10 ? `0${value}` : value;

                    // uses singular form when the value is less than 2
                    // const word = value < 2 ? key.replace(/s$/, '') : key;

                    props[key] = `${digits}`;
                });

                return props;
            },
        }
    }
</script>

<style scoped>
    .quiz-timer {
        font-size: 3em;
        /*position: absolute;*/
        /*right: 0;*/
        /*top: 28%;*/
    }
</style>